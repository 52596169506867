import React from "react"
import Img from "../components/image-transform"

const gateways = ({content}) => {
  return (
    <div className="gateway-holder"
    style={{ backgroundColor: content.backgroundColor,color: content.textColor}}>
    <div className="container">
      <p className="title">{content.title}</p>
      <p className="text">{content.text}</p>

      <div className="gateway-flex">
        {
          content.block.map((item, i) =>{
            return(
              <div className="gateway-block" key={i}>
                {
                  item.svg ? <img src={item.img} alt={item.brandName ? item.brandName : 'gateway-img'} /> :
                  <Img filename={item.img}  alt={item.brandName ? item.brandName : 'gateway-img'}/>
                }
              </div>
            )
          })
        }
      </div>
      {
        content.isUnderConstruction ?       
        <div className="under-construction">
          <img src={require("../images/roadmap.svg")} alt="underconstruction" />
        </div> : ''
      }
    </div>
  </div>
  )
}

export default gateways
