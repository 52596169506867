import React from "react"
import classNames from 'classnames'

const planActionInAction = ({data}) => {
  return(
    <div className="action-in-action">
      <div className="container">
      <p className="title">{data.title}</p>

        {
          data.action.map((item, i) => {
            return(
              <div className={classNames('action-holder', 
                {"col-reverse" : item.detailReverse === true},
              )} key={i}>
                <div className="image-holder">
                  <img className="icon-image" src={item.image} alt={data.alt} />
                </div>
                <div className="detail-box">
                  <p className="heading">{item.heading}</p>
                  <p className="text">{item.text}</p>
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  ) 
}

export default planActionInAction